import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { getLang, __ } from "../helper/utils";
import ThePackengersLogo from "../components/Logo/ThePackengersLogo";
import { Container, Typography } from "@mui/material";
import UnikbaseAppIllustration from "../page/LandingPage/UnikbaseAppIllustration";
import './Header.scss';
import { useUrlParam } from "../page/LandingPage/LandingPage";
import Logo from "../components/Logo/Logo";
import { fetchAuthToken, useLazyGetPersistenceOperatorsQuery } from "../api/api";
import { Operators } from "../helper/constants";
import i18n, { languages } from "../lang/i18n";
import { t } from "i18next";

const Header = () => {
  let navigate = useNavigate();
	const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
	const urlParams = new URLSearchParams(window.location.search);

	const [fetchOperators] = useLazyGetPersistenceOperatorsQuery();

	let langUrl = useUrlParam("lang", 'en');
	const [lang, setLang] = useState(langUrl); 
	const [langMenu, toggleMenu] = useState(false);

	const langSwitch = (key) => {
		setLang(key);
		urlParams.set('lang', key);
		// setSearchParams({ lang: key })
    const newURL = `${location.pathname}?${urlParams.toString()}`;
		
		navigate(newURL, { replace: true })	
		toggleMenu(false);
	}

	useEffect(() => {
		if (lang && languages.includes(lang)) {
			i18n.changeLanguage(lang === 'fr' ? lang : 'en');
		}
	}, [lang]);

	useEffect(() => {
		if (langUrl !== lang) {
			setLang(langUrl)
			window.location.reload()
		}
	}, [lang, langUrl])

	useEffect(() => {
		const [username, password] = process.env.REACT_APP_BASIC_AUTH.split(':');
		fetchAuthToken(username, password)
			.then((res) => {
				// fetchOperators({ token: res.access_token }) // not yet available
			})
			.catch(console.error);
	}, [])

	const operator = useMemo(() => {
		if (/\/ars\/?/.test(location.pathname)) {
			return Operators.ARS;
		} else {
			return Operators.TPK;
		}
	 }, [location]);

	return (
		<header className="site__navigation site__navigation--white">
			<Container
				maxWidth="lg"
				className="header"
			>
				<div className="row">
					<div className="brand">
						<a href={operator.homepage} rel="noreferrer" target="_blank" title={operator.name}>
							<ThePackengersLogo operator={operator} />
						</a>
						
						<div className="unikbase--brand">
							<div className="wrapper">
								<span>{t('texts.powered_by')}</span>
								<Logo textColor="#001c26" />
							</div>
						</div>	
					</div>
				</div>
			</Container>
		</header>
	);
};

export default Header;
