import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { HashRouter as Router } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { Provider as Redux } from "react-redux";
import store from "./store/rootReducer";
import './lang/i18n';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<>
		<Redux store={store}>
			<Router>
				<App />
			</Router>
			<ToastContainer />
		</Redux>
	</>
);
