import React, { useEffect, useState, useMemo } from "react";
import { Typography, Container } from "@mui/material";
import { t } from "i18next";
import { toast } from "react-toastify";

import { getLang } from "../../helper/utils";
import { planFeaturesColumns, prices, pricing_plans } from "../../config/product";

import useHttp from "../../hooks/use-Http";
import PricingTableColumn from "./PricingTableColumn";
import { Operators } from "../../helper/constants";

import "./LandingPage.scss";
import { useLocation, useSearchParams } from "react-router-dom";

import Tracking from "../../components/Tracking/Tracking";
import UnikbaseAppIllustration from "./UnikbaseAppIllustration";
import Video from "./Video";
import Page from "../../layout/Page";
import GoogleImageCustomSearch from "../../components/GoogleImageCustomSearch";

export const REDIRECT_URL = process.env.REACT_APP_REDIRECT_URL || "https://unikbase.com";
export const TPK_SIGNUP_URL = process.env.REACT_APP_TPK_SIGNUP_URL;
export const STRIPE_PAYMENT_URL =
  process.env.REACT_APP_STRIPE_PAYMENT_URL || "https://dev1.unikbase.dev/meveo/rest/stripe-checkout";

export function useUrlParam(key, def = "") {
  const [searchParams] = useSearchParams();
  const urlParams = new URLSearchParams(window.location.search);
  let value = urlParams.get(key);
  if (!value) value = searchParams.get(key);

  return value || def;
}

export const hexToBinary = (hexString) => {
  let binaryString = "";
  for (let i = 0; i < hexString.length; i += 2) {
    binaryString += parseInt(hexString.substr(i, 2), 16).toString(2).padStart(8, "0");
  }
  return binaryString;
};

export const digestMessage = async (message, algo = 'SHA-256') => {
  const encodedMessage = new TextEncoder().encode(message);
  const hashBuffer = await crypto.subtle.digest(algo, encodedMessage);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hexHash = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
  return hexHash;
}

const LandingPageContent = () => {
  const location = useLocation();
  const [status, setStatus] = useState("idle");
  const [pricingColumns, setPricingColumns] = useState([]);
  const [pricePlans, setPricePlans] = useState({});
  const [testingABId, setABTestingId] = useState('');
  const [selectedOffer, setSelectedOffer] = useState('');
  const [redirectURL, setRedirectURL] = useState('');

  let lang = useUrlParam("lang");
  if (!lang) lang = navigator.language || navigator.userLanguage;
  //Converting tpk_id to get price from table
  let tpk_jwt = useUrlParam("token"); // TPK access token to check original order
  let tpk_id = useUrlParam("tpk_id"); // order id
  let _image = useUrlParam("image");
  let value = useUrlParam('value', 0);
  let currency = useUrlParam("currency", 'EUR');
  let token_name = useUrlParam("name");
  let token_description = useUrlParam("description");
  let email = useUrlParam("email", "Email@mail.com");
  const [image, setImage] = useState(_image);
  const _channel = useUrlParam("channel");
  const channel = _channel === "1" ? "email" : "web"

  if (!token_name || token_name.length === 0) {
    token_name = !token_description ? 'N/A' : token_description.substring(0, 25);
  }

  const operator = useMemo(() => {
    if (/\/ars\/?/.test(location.pathname)) {
      return Operators.ARS;
    } else {
      return Operators.TPK;
    }
  }, [location]);

  const language = getLang(lang); //Replace it later by func
  const pageTexts = language.pages.landing_page;

  let pricingTables = useMemo(() => pageTexts.pricing_table || {}, [pageTexts]);
  const footerImages = useMemo(() => ({
    Free: '',
    Standard: require("../../assets/standard_fig.jpg"),
    Plus: require("../../assets/plus_fig.jpg"),
  }), []);

  const {
    sendRequest: sendPostRequest,
    setIsLoading: setIsLoadingPost,
    setHttpError: setHttpErrorPost,
  } = useHttp({
    url: STRIPE_PAYMENT_URL,
    method: "POST",
  });
  // const {isLoading:isLoadingGet,hasError:httpGetError,sendRequest:sendGetRequest,setIsLoading:setIsLoadingGet,setHttpError:setHttpErrorGet} = useHttp({url:'https://checkout.stripe.com',method: 'GET'})

  useEffect(() => {
    if (!tpk_id) {
      window.location.replace(REDIRECT_URL);
      return;
    }
  }, [tpk_id]);

  useEffect(() => {
    var button = document.getElementById(`gtm-button-${operator.code}-onboarding`);
    var channelButton = document.getElementById(`${operator.code}-channel-${channel}`);
    if (button) {
      setTimeout(() => {
        button.click();
        channelButton && channelButton.click();
      }, 2000);
    }
  }, [operator]);


  useEffect(() => {
    if (selectedOffer) {
      var button = document.getElementById(`gtm-button-${operator.code}-${selectedOffer}-${testingABId}`);
      if (button) {
        button.click();
      }
    }
  }, [selectedOffer, testingABId, operator]);

  useEffect(() => {
    if (redirectURL && selectedOffer) {
      window.location.replace(redirectURL)
    }
  }, [redirectURL, selectedOffer]);

  useEffect(() => {
    const planIndex = async (input) => {
      let hash = await digestMessage(input);
      let binaryString = hexToBinary(hash);

      if (value > 1000) { // greater than 1000$ apply rule 2
        return parseInt(binaryString.substring(binaryString.length - 2), 2); // A, B, C, D 25% each
      } else { // less than 1000$ apply rule 1
        const size = 8;
        const numOfPlans = 3;

        const num = parseInt(binaryString.substring(binaryString.length - size), 2);
        const max = Math.pow(2, size);

        const division = Math.floor(num / (max / numOfPlans)); // A, B, D 33% each
        return division >= 2 ? 3 : division; // there is no C
      }
    };

    const updatePricingColumns = async () => {
      if (email !== null && email !== "Email@mail.com") {
        let plan = await planIndex(email);
        setABTestingId(Object.keys(pricing_plans)[plan]);
        setPricePlans(pricing_plans[Object.keys(pricing_plans)[plan]]);
        setPricingColumns(planFeaturesColumns);
      }
    }
    updatePricingColumns();
  }, [email, pricingTables, value]);

  const submitHandler = (data) => {
    if (!email) {
      return toast.error(t('pages.landing_page.email_is_missing'))
    }

    let payload = {
      email: email,
      tpk_id: tpk_id.startsWith("TPK_") ? tpk_id : "TPK_" + tpk_id, // Adding the "TPK_" prefix to the tpk_id to get the correct stripe config in backend
      price_id: data.price_id,
      value: "" + value, //price_item
      image: image,
      description: token_description,
      name: token_name,
      commercialOfferCode: data?.code,
      currency,
      abPlanId: testingABId,
      tpk_token: tpk_jwt,
    };
    setStatus("loading");
    if (data.value <= 0) {
      // Send to collector page
      const signupLink =
        TPK_SIGNUP_URL +
        `?tpk_id=${payload.tpk_id}&email=${email}&name=${encodeURIComponent(token_name)}&value=${value}&currency=${currency}&description=${encodeURIComponent(token_description)}&image=${encodeURIComponent(image)}&plan=${data.value || "free"
        }&commercialOfferCode=${data?.code}&abPlanId=${testingABId}&tpk_token=${encodeURIComponent(tpk_jwt)}`;

      setSelectedOffer(payload.commercialOfferCode);
      setRedirectURL(signupLink);
    } else {
      // send to stripe
      sendPostRequest(payload)
        .catch((error) => {
          setIsLoadingPost(false);
          setHttpErrorPost(error.message);
        })
        .then((url) => {
          if (url) {
            let session_id = url.split("https://checkout.stripe.com/c/pay/").join("");
            session_id = session_id.split("#")[0];

            localStorage.setItem("session_id", session_id);
            localStorage.setItem(
              `_tpk_request_${session_id}`,
              JSON.stringify({
                ...payload,
                token_name,
                token_description,
                image,
              })
            );
            setSelectedOffer(payload.commercialOfferCode);
            setRedirectURL(url)
          }
        });
    }
  };

  return (
    <Page status={status}>
      {!!tpk_id && (
        <Container maxWidth="lg" component="section" className="section">
          <Tracking order={tpk_id} />

          <div className="row">
            <div className="unikbase-data col col--1" style={{ paddingLeft: 0, paddingRight: 0 }}>
              <div className="row">
                <div id="product" className="col col--1-3 bg--white">
                  <div className="product">
                    <div className="product__thumbnail thumbnail">
                      <div
                        className="thumbnail__wrap"
                        style={{
                          backgroundImage: `url(${image})`,
                        }}
                      >
                        {image && (
                          <img
                            style={{
                              objectFit: 'contain'
                            }}
                            src={image}
                            alt={token_name || ""}
                          ></img>
                        )}
                      </div>
                    </div>
                    <h3 className="product__title">{token_name || "Object name"}</h3>
                    <Typography className="product_description" component="div" variant="body1">
                      {token_description || ""}
                    </Typography>
                  </div>
                </div>

                <div
                  id="about-tpk"
                  className="col col--2-3"
                  style={{
                    position: "relative",
                  }}
                >
                  <div className="row content">
                    <div className="col col--2-3">
                      <Typography component="h3" variant="body1">
                        {t("pages.landing_page.title", { operator: operator.name })}
                      </Typography>

                      <Typography component="h4" variant="body1">
                        {t('pages.landing_page.sub_title')}
                      </Typography>
                    </div>
                  </div>
                  <UnikbaseAppIllustration />
                </div>
              </div>

              <div id="pricing-plans" className="row">
                <div className="col col--1">
                  <div className="row">
                    {!!pricingColumns &&
                      pricingColumns.map((column, idx) => {
                        let pricePlan = pricePlans[column.title];
                        let price = prices.find((el) => el.value === pricePlan.price);
                        if (price && pricePlan) {
                          pricePlan.currency = price.currency === '$' ? 'USD' : 'EUR';
                          pricePlan.price_id = price.id;
                        }
                        return (
                          <PricingTableColumn
                            key={`offer-${idx}`}
                            id={`column-${idx}`}
                            price={price}
                            pricePlan={pricePlan}
                            planPeriod={true}
                            image={image}
                            column={column}
                            callback={submitHandler}
                            imageType={idx >= 1 ? "3D" : ""}
                            options={pricingTables?.options.map(e => e.replace(/\{\{operator\}\}/g, operator.name))}
                            footerImage={footerImages[column.title]}
                          />
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      )}

      <Container maxWidth="lg" component="section" className="section" id="about-digital-passport">
        <div className="row">
          <div className="col col--1-3 bg--gray">
            <Video />
          </div>
          <div className="col bg--blue col--2-3">
            <Typography component="h3" variant="body1">
              {t('pages.landing_page.what_is_digital_passport')}
            </Typography>

            <Typography
              component="div"
              variant="body1"
              dangerouslySetInnerHTML={{ __html: t('pages.landing_page.what_is_digital_passport_paragraph', {operator: operator.name}) }}
            ></Typography>
          </div>
        </div>
      </Container>

      <Container maxWidth="lg" component="section" className="section" id="benefits">
        <div className="row">
          <div className="col col--2-3 bg--gray">
            <Typography component="h3" varaint="body1">
              {t('pages.landing_page.benefits')}
            </Typography>
            <ul className="list">
              {!!pageTexts.benefit_items &&
                pageTexts.benefit_items.map((i, idx) => (
                  <li key={`benefit-item-key-${idx}`} className="item">
                    <span className="item__title">{i.title}</span>
                    {i.content.replace(/\{\{operator\}\}/g, operator.name)}
                  </li>
                ))}
            </ul>
          </div>
          <div
            className="col col--1-3 benefit-cover-image"
            style={{
              backgroundImage: `url(${require("../../assets/benefit-bg.png")})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          ></div>
        </div>
      </Container>
      <GoogleImageCustomSearch
        open={!!email && !image}
        onSetImage={setImage}
        query={token_description}
      />
      <button id={`gtm-button-${operator.code}-${selectedOffer}-${testingABId}`} type="button" style={{ display: 'none' }}></button>
      <button id={`gtm-button-${operator.code}-onboarding`} type="button" style={{ display: 'none' }}></button>
      <button id={`${operator.code}-channel-${channel}`} type="button" style={{ display: 'none' }}></button>
    </Page>
  );
};

export default LandingPageContent;
