export const prices = [
  {
    id: 0,
    value: 0,
    currency: '$'
  },
  {
    id: 1,
    value: 9.9,
    currency: '$'
  },
  {
    id: 2,
    value: 19.9,
    currency: '$'
  },
  {
    id: 3,
    value: 1.9,
    currency: '$'
  },
  {
    id: 4,
    value: 39.9,
    currency: '$'
  },
  {
    id: 5,
    value: 4.9,
    currency: '$'
  },
  {
    id: 6,
    value: 14.9,
    currency: '$'
  },
];


export const pricing_plans = {
  A: {
    Basic: {
      price: 0,
      hosting: 3, //months
      code: 'BASIC',
    },
    Standard: {
      price: 9.9,
      hosting: 24,  //months
      code: 'STANDARD',
    },
    Plus: {
      price: 19.9,
      hosting: 24, //months
      code: 'PLUS',
    }
  },
  B: {
    Basic: {
      price: 1.9,
      hosting: 12, //months
      code: 'BASIC',
    },
    Standard: {
      price: 9.9,
      hosting: 24, //months
      code: 'STANDARD',
    },
    Plus: {
      price: 19.9,
      hosting: 24, //months
      code: 'PLUS',
    }
  },
  C: {
    Basic: {
      price: 1.9,
      hosting: 12, //months
      code: 'BASIC',
    },
    Standard: {
      price: 19.9,
      hosting: 24, //months
      code: 'STANDARD',
    },
    Plus: {
      price: 39.9,
      hosting: 24, //months
      code: 'PLUS',
    },
  },
  D: {
    Basic: {
      price: 0,
      hosting: 3, //months
      code: 'BASIC',
    },
    Standard: {
      price: 4.9,
      hosting: 24, //months
      code: 'STANDARD',
    },
    Plus: {
      price: 14.9,
      hosting: 24, //months
      code: 'PLUS',
    },
  }
}

export const planFeaturesColumns = [
  {
    "id": 0,
    "title": "Basic",
    "options": [
      true,
      true,
      true,
      false,
      false,
      false,
      false,
      false,
      false,
      false
    ]
  },
  {
    "id": 1,
    "title": "Standard",
    "options": [
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      false
    ]
  },
  {
    "id": 2,
    "title": "Plus",
    "options": [
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true
    ]
  }
]

export const pricing_plans_ARS = {
  A: {
    Basic: {
      price: 0,
      code: 'BASIC',
    },
    Standard: {
      price: 9.9,
      code: 'STANDARD',
    },
    Plus: {
      price: 19.9,
      code: 'PLUS',
    }
  },
  B: {
    Basic: {
      price: 1.9,
      code: 'BASIC',
    },
    Standard: {
      price: 9.9,
      code: 'STANDARD',
    },
    Plus: {
      price: 19.9,
      code: 'PLUS',
    }
  },
  C: {
    Basic: {
      price: 0,
      code: 'BASIC',
    },
    Standard: {
      price: 4.9,
      code: 'STANDARD',
    },
    Plus: {
      price: 14.9,
      code: 'PLUS',
    },
  },
}

export const planFeaturesColumnsARS = [
  {
    "id": 0,
    "title": "Basic",
    "options": [
      true,
      true,
      true,
      true,
    ]
  },
  {
    "id": 1,
    "title": "Standard",
    "options": [
      true,
      true,
      true,
      true,
      true,
    ]
  },
  {
    "id": 2,
    "title": "Plus",
    "options": [
      true,
      true,
      true,
    ]
  }
]